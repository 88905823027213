<template>
  <div>
    <v-card class="custom-buttons-holder">
      <v-container fluid>
        <v-row justify="end">
          <v-col cols="12">
            <v-row class="first-row mb-0" >
              <v-col cols="3">
                <v-select
                  v-model="selectedYear"
                  :items="orderedYears"
                  outlined
                  hide-details=""
                  @change="yearChange"
                  menu-props="auto"
                ></v-select>
              </v-col>
              <v-col cols="3">
              </v-col>

              <v-col cols="3">
                <a class="filter">
                  <v-switch inset v-model="showActualInventory" @change="getInventarData(selectedYear)"></v-switch>
                  <span>{{ showActualInventory ? 'Aktuelle Inventare' : 'Archivierte Inventare' }}</span>
                </a>
              </v-col>
              <!-- <v-col cols="2">
                <a class="print" @click="downloadExcel">
                  <img class="custom-icon" src="../../../assets/images/icons/export.svg" />
                  <span class="mdi mdi-open-in-new custom-icon"></span>
                  <span>Inventar exportieren</span>
                </a>
              </v-col> -->
              <!-- <v-col cols="2">
                <label class="print" style="cursor:pointer" for="fileInput">
                  <img class="custom-icon" src="../../../assets/images/icons/import.svg" /> 
                  <input type="file" id="fileInput" style="display: none" @change="handleFileUpload" accept=".xlsx" />
                  <span>Inventar importieren</span>
                </label>
              </v-col> -->
              <v-col cols="3" class="pa-0">
                <v-btn
                  block
                  size="x-large"
                  height="54px"
                  color="#1db954"
                  class="download-btn"
                  @click.prevent="goToCreateInventar"
                >
                  <v-icon>mdi mdi-plus-thick</v-icon>
                  Neues Inventar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-if="!showActualInventory" class="mt-4 mb-4">
      <v-card-text style="font-size: 15px !important;">
        Geschichte des Inventars
      </v-card-text>
    </v-card>

    <v-card :loading="isloading">
      <InventarDatatable
        :headers="headers"
        :tableData="tableData"
        :showSelect="true"
        :singleSelect="true"
        :selectedItemsProp="this.inventarId"
        :locked="locked"
        :actions="[
          {
            id: 5,
            action: 'lock',
            icons: require('../../../assets/images/icons/lock.svg'),
            params: '',
            show: false
          },
          {
            id: 6,
            action: 'unlock',
            icons: require('../../../assets/images/icons/unlock.svg'),
            params: '',
            show: false
          },
          {
            id: 1,
            icons: require('../../../assets/images/icons/eye-outline.svg'),
            action: 'view',
            params: '',
            disabled: false
          },
          {
            id: 2,
            icons: require('../../../assets/images/icons/pencil-outline.svg'),
            action: 'edit',
            params: '',
            disabled: true
          },
          {
            id: 3,
            icons: require('../../../assets/images/icons/marker-outline.svg'),
            action: 'map',
            params: '',
            disabled: true
          },
          {
            id: 4,
            icons: require('../../../assets/images/icons/unarchive-tree.svg'),
            action: 'archive',
            params: '',
            show: false
          }
        ]"
        @edit="editMethod"
        @map="mapMethod"
        @view="viewMethod"
        @archive="archiveMethod"
        @lock="lockMethod"
        @unlock="unlockMethod"
        @selectedRow="selectedRowMethod"
        :showActualInventory="showActualInventory"
      />
    </v-card>


    <v-alert type="success"  elevation="20" v-show="archiveModalVisible"  class="alert">
      Das Inventar wurde erfolgreich wiederhergestellt
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InventarDatatable from "../../../components/custom_components/InventarDatatable.vue";
import axios from "axios";

export default {
  components: {
    InventarDatatable
  },
  data() {
    return {
      isloading: true,
      showSelect: true,
      headers: [
        {
          text: "Inventarname",
          align: "start",
          value: "name"
        },
        { text: "Total Bäume[Stk.]", value: "totalTrees" },
        { text: "Norm [h]", value: "normalHours" },
        { text: "Spez [h]", value: "specialHours" },
        { text: "Aufwand Material[Fr.]", value: "material" },
        { text: "", value: "buttons", align: "start", sortable: false }
      ],
      tableData: [],
      years: [],
      locked: [],
      selectedInventarId: null,
      selectedYear: this.inventarSelectedYear
        ? this.inventarSelectedYear
        : new Date().getFullYear(),
      mapRegions: [],
      showActualInventory: true,
      archiveModalVisible: false
    };
  },
  computed: {
    ...mapGetters(["getThemeMode", "inventarId", "inventarSelectedYear", "inventarTitle","editedInventar"]),

    orderedYears() {
      return this.years.slice().reverse();
    }
  },

  created() {
    this.fetchRegions();
  },
  mounted() {
    this.fetchYears();
    if (this.inventarSelectedYear !== null) {
      this.selectedYear = this.inventarSelectedYear;
    }
    this.getInventarData(this.selectedYear);
    this.updateSelectedYear(this.selectedYear);
    this.selectedInventarId = this.inventarId;
  },
  methods: {
    ...mapActions([
      "changeThemeLayout",
      "updateInventarId",
      "updateInventarTitle",
      "updateSelectedYear",
      "updateInventarCoordinates",
      "updateInventarCurrentPage",
      "updateLastEditedInventar"
    ]),
    goToCreateInventar() {
      this.$router.push("/app/pages/create-inventar");
    },
    async fetchYears() {
      try {
        const response = await axios.get(
          "https://tilia-ch.rrota.org/api/Inventory/GetYears"
        );
        this.years = response.data;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },

    fetchRegions() {
      axios
        .get("https://tilia-ch.rrota.org/api/Inventory/MapRegion")
        .then(response => {
          // Handle the response from the server
          this.mapRegions = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getInventarData(y) {
      try {
        let response = [];
      if (this.showActualInventory) {
        response = await axios.get(
          "https://tilia-ch.rrota.org/api/Inventory/GetAll/" + y
        );
      } else{
        response = await axios.get(
          "https://tilia-ch.rrota.org/api/Inventory/GetAll/" + y + "?archived=true"
        );
      } 
      
        if (this.editedInventar && response.data.length > 0) {
          const filteredTable = [...response.data];
          const editedItemIndex = filteredTable.findIndex(item => item.id === this.editedInventar);

          if (editedItemIndex !== -1) {
            const editedItem = filteredTable.splice(editedItemIndex, 1)[0]; // Remove the edited item
            filteredTable.unshift(editedItem); // Move it to the beginning
            this.tableData = filteredTable; // Update tableData
          }
        } else {
          this.tableData = response.data;
        }
        for (let i = 0; i < response.data.length; i++) {
        response.data[i].normalHours = response.data[i].normalHours.toFixed(2);
        response.data[i].specialHours = response.data[i].specialHours.toFixed(2);
        response.data[i].material = response.data[i].material.toFixed(2);
    }

        this.isloading = false;
        this.locked = this.tableData.filter(item => item.isLocked);
      } catch (error) {
        console.log(error);
        this.hasError = true;
        this.isloading = false;
      }
    },
    yearChange(year) {
      this.updateSelectedYear(year);
      this.getInventarData(year);
    },
    editMethod(id) {
      this.updateLastEditedInventar(id);
      const currentPageInventar = localStorage.getItem('tableInventarCurrentPage');
      let coordinates = null;
      let defaultCoordinates = '[[47.161601180660426,7.509919405360439],[47.16381816506552,7.522782314721083],[47.15484358103445,7.519523711016413],[47.15436616697559,7.510776932651133]]';

      if (this.mapRegions.length > 0) {
        const selectedInventar = this.mapRegions.find(
          el => el.inventoryId == id
        );

        if (selectedInventar !== undefined) {
          coordinates = JSON.stringify(selectedInventar.coordinates);
        }
      }

      if (coordinates !== null) {
        this.updateInventarCoordinates(coordinates);
      }else {
        this.updateInventarCoordinates(defaultCoordinates);
        
      }
      this.$router.push({
        name: "Inventar bearbeiten",
        params: { id: id, year: this.inventarSelectedYear }
      });

        this.selectedInventarId = id;
        this.updateInventarId(id);
        this.updateInventarCurrentPage(currentPageInventar);
        if (this.tableData.length > 0) {
        const selectedInventar = this.tableData.find(
          el => el.id == id
        );
        this.updateInventarTitle(null);
        this.updateInventarTitle(selectedInventar.name);
      }
      
    },
    viewMethod(id, name, date, item) {
      console.log("item",item);
      let coordinates = null;
      let defaultCoordinates = '[[47.161601180660426,7.509919405360439],[47.16381816506552,7.522782314721083],[47.15484358103445,7.519523711016413],[47.15436616697559,7.510776932651133]]';

      const currentPageInventar = localStorage.getItem('tableInventarCurrentPage');
      if (this.mapRegions.length > 0) {
        const selectedInventar = this.mapRegions.find(
          el => el.inventoryId == id
        );

        if (selectedInventar !== undefined) {
          coordinates = JSON.stringify(selectedInventar.coordinates);
        }
      }

      if (coordinates !== null) {
        this.updateInventarCoordinates(coordinates);
      }else {
        this.updateInventarCoordinates(defaultCoordinates);
      }
      let d = new Date(date);
      const year = d.getFullYear();

      this.$router.push({
        name: "InventarInfo",
        query: { id: id },
        params: { title: name, year: this.inventarSelectedYear, canEdit: item.canEdit }
      });

        this.updateLastEditedInventar(id);

        this.selectedInventarId = id;
        this.updateInventarId(id);
        this.updateInventarCurrentPage(currentPageInventar);

        if (this.tableData.length > 0) {
        const selectedInventar = this.tableData.find(
          el => el.id == id
        );
        this.updateInventarTitle(null);
        this.updateInventarTitle(selectedInventar.name);
      }
      
    },
    mapMethod(id) {
      let coordinates = null;
      let defaultCoordinates = '[[47.161601180660426,7.509919405360439],[47.16381816506552,7.522782314721083],[47.15484358103445,7.519523711016413],[47.15436616697559,7.510776932651133]]';
      const currentPageInventar = localStorage.getItem('tableInventarCurrentPage');
      if (this.mapRegions.length > 0) {
        const selectedInventar = this.mapRegions.find(
          el => el.inventoryId == id
        );
       
        coordinates = selectedInventar != undefined ? JSON.stringify(selectedInventar.coordinates) : null;
      }

      if (coordinates !== null) {
        this.updateInventarCoordinates(coordinates);
      }else {
        this.updateInventarCoordinates(defaultCoordinates);
      }

      if(coordinates !== null){
          this.$router.push({
          name: "Inventarliste",
          params: { id: id, year: this.inventarSelectedYear, coord: coordinates }
        });
        this.updateLastEditedInventar(id);
      }
 
        this.selectedInventarId = id;
        this.updateInventarId(id);
        this.updateInventarCurrentPage(currentPageInventar);
        if (this.tableData.length > 0) {
        const selectedInventar = this.tableData.find(
          el => el.id == id
        );
        this.updateInventarTitle(null);
        this.updateInventarTitle(selectedInventar.name);

      }
      
    },
    selectedRowMethod(id) {
      this.updateLastEditedInventar(id);
      let coordinates = null;
      let defaultCoordinates = '[[47.161601180660426,7.509919405360439],[47.16381816506552,7.522782314721083],[47.15484358103445,7.519523711016413],[47.15436616697559,7.510776932651133]]';

      if (this.mapRegions.length > 0) {
        const selectedInventar = this.mapRegions.find(
          el => el.inventoryId == id
        );

        if (selectedInventar !== undefined) {
          coordinates = JSON.stringify(selectedInventar.coordinates);
        }
      }

      if (coordinates !== null) {
        this.updateInventarCoordinates(coordinates);
      } else {
        this.updateInventarCoordinates(defaultCoordinates);
      }
      /* handle select or deselect */
      /* if id that comes from method is equal with selectedinventarid means we are deselecting */
      if (this.selectedInventarId === id) {
        this.selectedInventarId = null;
        this.updateInventarId(null);
        this.updateInventarTitle(null);
      } else {
        this.selectedInventarId = id;
        this.updateInventarId(id);
        if (this.tableData.length > 0) {
        const selectedInventar = this.tableData.find(
          el => el.id == id
        );
        this.updateInventarTitle(null);
        this.updateInventarTitle(selectedInventar.name);
      }
      }
    },

    async archiveMethod(item) {
      try {
        const response = await axios.post(`https://tilia-ch.rrota.org/api/Inventory/UnarchiveInventory/` + item);
        this.getInventarData(this.selectedYear);
        this.openModal();
      } catch (error) {
        console.log('Error unarchiving tree', error);
      }
    },
    openModal() {
      this.archiveModalVisible = true;
      setTimeout(() => {
        this.archiveModalVisible = false;
      }, 4000); 
    },

    async lockMethod(item) {
      try {
            const response = await axios.post(`https://tilia-ch.rrota.org/api/Inventory/LockUnlockInventory/`+ item + `/true`);
            this.getInventarData(this.selectedYear);
      } catch (error) {
          console.log('Error locking inventory', error);
        }
    },
    async unlockMethod(item) {
      try {
        
        let userInfo = JSON.parse(localStorage.getItem('userInfo')) || [];
        let fullName = userInfo[0].user.fullName.toLowerCase();

        let lockedData = this.tableData.find(i => i.id === item);
        if (lockedData) {
          let lockedInfo = {
              isLocked: lockedData.isLocked,
              lockedBy: lockedData.lockedBy.toLowerCase()
          };
          if(lockedInfo.isLocked){
            if(lockedInfo.lockedBy == fullName){
              const response = await axios.post(`https://tilia-ch.rrota.org/api/Inventory/LockUnlockInventory/`+ item + `/false`);
              this.getInventarData(this.selectedYear);
            } else {

            }
          } 
        }
      } catch (error) {
          console.log('Error locking inventory', error);
        }
    },

    async downloadExcel() {
      try {
        const response = await axios.get(
          `https://tilia-ch.rrota.org/api/Inventory/InventoryExport/${this.selectedYear}`
        ).then(response => {
          window.open(response.data, '_blank');
        }).catch(console.error);
      }
      catch (error) {
        this.isLoading = false;
        console.log(error);
        this.hasError = true;
      }
    },

    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        return; 
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post('https://tilia-ch.rrota.org/api/Inventory/InventoryImport', formData, {
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        });

      } catch (error) {
        
        console.error('Error uploading file:', error);
      }
    }
  }
};
</script>
